import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
}

let secrateKey = localStorage.getItem('k')
console.log(secrateKey)
if (secrateKey !== null) {
  secrateKey = atob(secrateKey)
}

const user = {
  id: 1,
  username: 'admin',
  password: '',
  email: '',
  firstname: 'Admin',
  lastname: '',
  pic: '',
}

const initialAuthState: IAuthState = {
  user: secrateKey ? user : undefined,
  accessToken: undefined,
  secrateKey,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  secrateKey?: string | null
  WebApiKey?: string
}
// const initialState = {}

export const reducer = persistReducer(
  {storage, key: 'v1-dhg-auth', whitelist: ['user', 'accessToken', 'secrateKey', 'WebApiKey']},
  (state: IAuthState = initialAuthState, action: any) => {
    switch (action.type) {
      case actionTypes.Login: {
        const secrateKey = action.payload.WebApiKey
        return {secrateKey}
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (data: any) => ({type: actionTypes.Login, payload: data}),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // const {data: user} = yield getUserByToken()
    yield put(actions.fulfillUser(user))
  })
}
