/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {/* <AsideMenuItem
        to='/device'
        icon='/media/icons/duotone/Devices/Server.svg'
        title={intl.formatMessage({ id: 'MENU.DEVICE' })}
        fontIcon='bi-app-indicator'
      /> */}

      <AsideMenuItemWithSub
        to='/device'
        icon='/media/icons/duotone/Devices/Server.svg'
        title={intl.formatMessage({id: 'MENU.DEVICE'})}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem to='/device/list' title='List' hasBullet={true} />
        <AsideMenuItem to='/device/info' title='Info' hasBullet={true} />
        {/* <AsideMenuItem to='/device/type' title='By type' hasBullet={true} />
        <AsideMenuItem to='/device/customer' title='By Customer' hasBullet={true} />
        <AsideMenuItem to='/device/status' title='By Status' hasBullet={true} />
        <AsideMenuItem to='/device/map' title='Map' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/sim-data'
        icon='/media/icons/duotone/Devices/SD-card.svg'
        title={intl.formatMessage({id: 'MENU.SIM_AND_DATA'})}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem to='/sim-data/list' title='List' hasBullet={true} />
        <AsideMenuItem to='/sim-data/search' title='Search' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/service'
        icon='/media/icons/duotone/Devices/CPU1.svg'
        title={intl.formatMessage({id: 'MENU.SERVICE'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/statistics'
        icon='/media/icons/duotone/Shopping/Chart-bar1.svg'
        title={intl.formatMessage({id: 'MENU.STATICS'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/analytic'
        icon='/media/icons/duotone/Shopping/Chart-pie.svg'
        title={intl.formatMessage({id: 'MENU.ANALYTIC'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/customers'
        icon='/media/icons/duotone/General/User.svg'
        title={intl.formatMessage({id: 'MENU.CUSTOMERS'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/logout'
        icon='/media/icons/duotone/Electric/Shutdown.svg'
        title={intl.formatMessage({id: 'MENU.LOGOUT'})}
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
